<template>
  <v-stepper v-model="el">
    <v-stepper-header>
      <v-stepper-step :complete="el > 1" step="1"> Pre Test </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="el > 2" step="2"> Approval </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="el > 3"> Post Test </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step step="4" :complete="el > 4"> Approval </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step step="5" :complete="el > 5"> Summary </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <Assessment />
      </v-stepper-content>

      <v-stepper-content step="2">
        <pretestApproval/>
      </v-stepper-content>

      <v-stepper-content step="3">
        <PostAssessment/>
      </v-stepper-content>
      <v-stepper-content step="4">
      <PostTestApproval/>
      </v-stepper-content>
      <v-stepper-content step="5">
       <AssessmentSummary/>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import Assessment from "./Assessment.vue";
import pretestApproval from "./pretestapproval.vue";
import PostAssessment from "./PostAssessment.vue"
import PostTestApproval from "./posttestapproval.vue"
import AssessmentSummary from "./assessmentsummary.vue"
import { sync } from "vuex-pathify";

export default {
  // name: "AssessmentOverview",
  components: {
    Assessment,
    pretestApproval,
    PostAssessment,
    PostTestApproval,
    AssessmentSummary
  },

  computed: {
    ...sync("assessment", ["el","pretestAssessment","pretestLearners"]),
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>
